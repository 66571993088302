import * as React from 'react'
import { graphql } from 'gatsby'

export const ReservationForm = ({ slice }) => {
  const {title, bg_img} = slice.primary


  return (
  <div className={"flex-fill py-80px"}>
		<div className={"container"}>
      {title && <h2 className="text-center text-primary">{title}</h2>}
      <div className="mt-5 bg-primary rounded p-5">
        <div className="row g-4">
          <div className="col-12 col-md-4">
            <select id="reservation-form-persons" className="form-select">
              <option value="1">1 Person</option>
              <option value="2">2 Personen</option>
              <option value="3">3 Personen</option>
              <option value="4">4 Personen</option>
              <option value="5">5+ Personen</option>
            </select>
          </div>
          <div className="col-12 col-md-4">
            <select id="reservation-form-date" className="form-select">
              <option value="1">31.12.2023</option>
            </select>
          </div>
          <div className="col-12 col-md-4">
            <select id="reservation-form-time" className="form-select">
              <option value="1">18:00</option>
            </select>
          </div>
          <div className="col-12 col-md-4">
            <input
              id="reservation-form-name"
              placeholder="Vor- und Zuname"
              className={'form-control'}
            />
          </div>
          <div className="col-12 col-md-4">
            <input
              id="reservation-form-email"
              placeholder="E-Mail"
              className={'form-control'}
            />
          </div>
          <div className="col-12 col-md-4">
            <input
              id="reservation-form-phone"
              placeholder="Telefonnummer"
              className={'form-control'}
            />
          </div>
          <div className="col-12">
            <textarea
              id="reservation-form-message"
              placeholder="Meldung"
              className={'form-control'}
            />
          </div>
        </div>
        <div className="text-center">
          <button className="btn btn-light mt-4" type="submit">ANFRAGE ABSENDEN</button>
        </div>
      </div>
		</div>
	</div>
  )
}

export const query = graphql`
  fragment PageDataBodyReservationForm on PrismicPageDataBodyReservationForm {
    primary {
      title
      bg_img {
        url
      }
    }
  }
`
