import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'
import Navbar from 'react-bootstrap/Navbar';

export const TopMenu = ({ topMenu, activeDocMeta }) => {
  const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink, index) => (
      <li key={`top-nav-${index}`} className="nav-item">
        <a className="nav-link h4 mb-0 active" aria-current="page" href={menuLink.anchor ? "#"+menuLink.anchor : menuLink.link.url}>{menuLink.label}</a>
      </li>
      ))
    : null

  return (
    <Navbar collapseOnSelect expand="lg" variant={"dark"} className={"py-3"}>
			<div className="container-xl">
        <a href="/" className="d-none d-sm-block"><img src="/logo-light.svg" alt="" height="40"/></a>
        <a href="/" className="d-block d-sm-none"><img src="/logo-titleless-light.svg" alt="" height="40"/></a>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav" className="">
          <div className="flex-fill d-flex justify-content-lg-end my-4 my-lg-0">
					  <ul className="navbar-nav">
              {renderedMenuLinks}
					  </ul>
          </div>
        </Navbar.Collapse>
			</div>
    </Navbar>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      menu_links {
        label
        link {
          id
          url
        }
        anchor
      }
    }
  }
`
