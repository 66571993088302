import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const TwoTitles = ({ slice }) => {
  const {pretitle_1, title_1, aftertitle_1, pretitle_2, title_2, aftertitle_2} = slice.primary

  return (
    <div className="">
        <div className="w-100 row gy-0">
          <div className="bg-primary py-80px col-12 col-md-6 text-center text-uppercase">
            <h3 className="text-white" style={{letterSpacing: "4px"}}>{pretitle_1}</h3>
            <h2 className="text-white my-4" style={{letterSpacing: "4px"}}>{title_1}</h2>
            <h4 className="text-white" style={{letterSpacing: "4px"}}>{aftertitle_1}</h4>
          </div>
          <div className="py-80px col-12 col-md-6 text-center text-uppercase">
            <h3 className="text-primary" style={{letterSpacing: "4px"}}>{pretitle_1}</h3>
            <h2 className="text-white my-4" style={{letterSpacing: "4px"}}>{title_1}</h2>
            <h4 className="text-primary" style={{letterSpacing: "4px"}}>{aftertitle_1}</h4>
          </div>
        </div>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyTwoTitles on PrismicHomepageDataBodyTwoTitles {
    primary {
      pretitle_1
      title_1
      aftertitle_1
      pretitle_2
      title_2
      aftertitle_2
    }
  }
`
