import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'

export const Jobs = ({ slice }) => {
  const {title, btn_label, btn_link} = slice.primary

  const jobs = slice.items.map((item, index) => {
    return (
      <div class="border p-4">
        <h5 className="text-primary">{item.title}</h5>
        <div className="mt-4">
          <PrismicRichText field={item.desc.richText} />
        </div>
      </div>
    );
  });

  return (
    <div className="bg-white text-dark py-80px">
      <div className="container">
        <h2 className="text-center text-primary">{title}</h2>
        <div className="mt-4">
          {jobs}
        </div>
        <p className="mt-4">Bitte senden Sie uns Ihre aussagekräftige Bewerbung an:</p>
        <a href={btn_link.url} className="btn btn-primary mt-4" type="submit">{btn_label}</a>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment PageDataBodyJobs on PrismicPageDataBodyJobs {
    primary {
      title
      btn_label
      btn_link {
        url
      }
    }
    items {
      title
      desc {
        richText
      }
    }
  }
`
