import { MainCover } from './MainCover'
import { Menu } from './Menu'
import { ReservationForm } from './ReservationForm'
import { TitleAndText } from './TitleAndText'
import { TwoTitles } from './TwoTitles'
import { ImgGalleryAndText } from './ImgGalleryAndText'
import { Jobs } from './Jobs'
import { Title } from './Title'

export const components = {
  main_cover: MainCover,
  menu: Menu,
  reservation_form: ReservationForm,
  title_and_text: TitleAndText,
  two_titles: TwoTitles,
  img_gallery_and_text: ImgGalleryAndText,
  jobs: Jobs,
  title: Title,
}
