import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import Carousel from 'react-bootstrap/Carousel';
import { Gallery, Item } from 'react-photoswipe-gallery'

export const MainCover = ({ slice }) => {
  const slides = slice.items.map((item, index) => {
    return (
      <Carousel.Item key={index} interval={5000}>
        <div className="d-flex flex-column justify-content-center align-items-center main-cover-bg" style={{backgroundImage: "url(" + item.cover_img.url + ")"}}>
            <div className="container py-80px">
              <div className="text-center main-title-shadow col-12 col-md-10 mx-auto">
                <h1 className="text-white main-title my-4">{item.title}</h1>
                <hr style={{width:"64px",color:"#fff",opacity:"1",borderTopWidth:"2px",marginLeft:"auto",marginRight:"auto"}}/>
                <h2 className="text-white main-subtitle">{item.subtitle}</h2>
              </div>
            </div>
        </div>
      </Carousel.Item>
    )
  })

  return (
    <div id="top">
      <Gallery>
        <Carousel controls={(slides.length > 1) ? true : false} indicators={(slides.length > 1) ? true : false}>
          {slides}
        </Carousel>
      </Gallery>
    </div>
  )
}

export const query = graphql`
  fragment HomepageDataBodyMainCover on PrismicHomepageDataBodyMainCover {
    items {
      title
      subtitle
      cover_img {
        url
      }
    }
  }
`
