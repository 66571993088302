import * as React from 'react'
import { Link, graphql } from 'gatsby'

export const Footer = ({ footer }) => {

  const footerLinks = footer
    ? footer.footer_links.map((footerLink, index) => (
      <a key={index} className="col-12 col-lg-6 nav-link h4 mt-0 mb-3 active" aria-current="page" href={footerLink.link.url}>{footerLink.label}</a>
      ))
    : null


  return (
    <footer id="footer">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-4 text-center text-md-start">
            <h6 className="lh-lg">
              Waldburgstraße 96<br/>
              71032 Böblingen<br/>
              Tel.: <a href="tel:070317213939" className="text-decoration-none" style={{color:"#1bc15e"}}>070317213939</a><br/>
              <a href="mailto:waldbiergarten@wiesengarten.com" className="text-decoration-none" style={{color:"#1bc15e"}}>waldbiergarten@wiesengarten.com</a><br/>
              Reservierungen ab 30 Personen möglich
            </h6>
          </div>
          <div className="col-12 col-md-4 text-center text-lg-start">
              <div className="row m-0 my-4 my-md-0 g-3">
                {footerLinks}
              </div>
          </div>
          <div className="col-12 col-md-4 text-center text-md-end">
            <h6 className="lh-lg">
              <a href="" className="text-decoration-none" style={{color:"#1bc15e"}}>Saison:&emsp;01.04. – 31.10.</a><br/>
              Mo-Fr:&emsp;11:00 – 22:00<br/>
              Sa, So:&emsp;11:00 – 22:00<br/>
              Letzter Ausschank: 21:30 Uhr<br/>
              Küche bis 21:00 Uhr
            </h6>
          </div>
        </div>
        <hr className="my-5"/>
        <div className="row gy-5">
          <a href="http://corners-sifi.de/" target="_blank" className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <img src="/Corners-white.svg" alt="" style={{maxHeight:"120px"}}/>
          </a>
          <a href="http://wiesengarten.online/" target="_blank" className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <img src="/Wiesengarten-white.svg" alt="" style={{maxHeight:"120px"}}/>
          </a>
          <a href="https://parkrestaurant-sindelfingen.de/" target="_blank" className="col-12 col-md-4 d-flex justify-content-center align-items-center">
            <img src="/Parkrestaurant-white.svg" alt="" style={{maxHeight:"120px"}}/>
          </a>
        </div>
        <hr className="my-5"/>
        <div className="text-center">
          <h6>Design & Umsetzung <a href="https://city-eats.de/" target="_blank" className="text-decoration-none" style={{color:"#1bc15e"}}>City-Eats.de</a></h6>
        </div>
      </div>
    </footer>
  )
}

export const query = graphql`
  fragment FooterFragment on PrismicFooter {
    id
    type
    data {
      copyright {
        richText
        text
      }
      footer_links {
        label
        link {
          url
        }
      }
    }
  }
`
