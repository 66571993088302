import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'
import Accordion from 'react-bootstrap/Accordion';

export const Menu = ({ slice }) => {

  const dishes = slice.items.map((item, index) => {
    return (
      <div key={index}>
        <div className="d-flex justify-content-between">
          <h6 className="text-primary">{item.name}</h6>
          {item.price && <h6 className="text-primary">{item.price.toFixed(2)} €</h6>}
        </div>
        <div className="" style={{fontSize:"0.875rem"}}>
          <PrismicRichText field={item.desc.richText} />
        </div>
          {(index + 1 != slice.items.length) &&
          <hr/>
          } 
      </div>
    )
  })

  return (
    <div className="container">
      <div className="mt-3">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>{slice.primary.title}</Accordion.Header>
            <Accordion.Body>
              {dishes}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment MenuDataBodyMenu on PrismicMenuDataBodyMenu {
    primary {
      title
    }
    items {
      name
      desc {
        richText
      }
      price
    }
  }
`
